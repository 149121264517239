import React from 'react';
import { S } from './DownloadReportButton.styles';
import { toast } from '../../../../../../components/Toast';
import { BackgroundControlWithFilesRiskEnum } from '@ansvar-sakerhet/api-client';

export const DownloadReportButton = ({
  risk,
  downloadUrl,
}: {
  risk: BackgroundControlWithFilesRiskEnum;
  downloadUrl: string;
}) => {
  const startDownload = () => {
    if (!downloadUrl) {
      return toast.error('Fil kunde inte hittas');
    }

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'rapport';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <S.DownloadButton
      onClick={startDownload}
      $noRisks={risk !== BackgroundControlWithFilesRiskEnum.NoRisk}
    >
      <S.IconWithBg src="Draft" /> Ladda ner rapport
    </S.DownloadButton>
  );
};
