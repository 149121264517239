import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { keys } from './overviewStatistics.key';

export const useOverviewStatistics = () => {
  const getApi = useApi();

  const getStats = async () => {
    const api = await getApi();
    const res = await api.clientOverviewStatisticsFindAll();

    return res.data;
  };

  const query = useQuery({
    queryFn: getStats,
    queryKey: keys.all,
  });

  return query;
};
