import React, { ReactNode } from 'react';
import { S } from './Statistics.style';
import CountUp from 'react-countup';
import { useOverviewStatistics } from '../../../../traits/api/overviewStatistics/useOverviewStatistics';
import { Loading } from '../../../../components/Loading';
import { RenderCounterProps } from 'react-countup/build/types';

export const Statistics = () => {
  const { data: stats, isLoading } = useOverviewStatistics();

  if (!stats || isLoading) {
    return <Loading />;
  }

  return (
    <>
      <S.StatsInner>
        <S.H5>Bakgrundskontroller</S.H5>
        <S.Margin $bottom="m" />

        <CountUpPreset value={stats.backgroundControls.amount}>
          {({ countUpRef }) => {
            return (
              <div>
                <S.H6>
                  Antal Kontroller: <span ref={countUpRef} /> st
                </S.H6>
              </div>
            );
          }}
        </CountUpPreset>
        <S.Margin $bottom="m" />

        <S.H6>Riskbedömning:</S.H6>
        <S.Margin $bottom="s" />

        <S.RiskAssessmentContainer>
          <S.RiskAssesmentInner>
            <CountUpPreset value={stats.backgroundControls.noRisk}>
              {({ countUpRef }) => {
                return (
                  <S.RiskSquare color="#DEFDAE">
                    <span>
                      Inga risker identifierade: <span ref={countUpRef} /> st
                    </span>
                  </S.RiskSquare>
                );
              }}
            </CountUpPreset>
          </S.RiskAssesmentInner>
          <S.RiskAssesmentInner>
            <CountUpPreset value={stats.backgroundControls.lesserRisk}>
              {({ countUpRef }) => {
                return (
                  <S.RiskSquare color="#FFFFB0">
                    <span>
                      Mindre väsentliga risker: <span ref={countUpRef} /> st
                    </span>
                  </S.RiskSquare>
                );
              }}
            </CountUpPreset>
          </S.RiskAssesmentInner>
        </S.RiskAssessmentContainer>
        <S.Margin $bottom="s" />

        <S.RiskAssessmentContainer>
          <S.RiskAssesmentInner>
            <CountUpPreset value={stats.backgroundControls.mediumHighRisk}>
              {({ countUpRef }) => {
                return (
                  <S.RiskSquare color="#D9BB77">
                    <span>
                      Medel-hög risk: <span ref={countUpRef} /> st
                    </span>
                  </S.RiskSquare>
                );
              }}
            </CountUpPreset>
          </S.RiskAssesmentInner>
          <S.RiskAssesmentInner>
            <CountUpPreset value={stats.backgroundControls.highRisk}>
              {({ countUpRef }) => {
                return (
                  <S.RiskSquare color="#E09E9B">
                    <span>
                      Hög risk: <span ref={countUpRef} /> st
                    </span>
                  </S.RiskSquare>
                );
              }}
            </CountUpPreset>
          </S.RiskAssesmentInner>
        </S.RiskAssessmentContainer>
      </S.StatsInner>
      <S.StatsInner>
        <S.H5>Arbetsplatskontroller</S.H5>
        <S.Margin $bottom="m" />

        <CountUpPreset value={stats.controls.amount}>
          {({ countUpRef }) => (
            <div>
              <S.H6>
                Antal Kontroller: <span ref={countUpRef} /> st
              </S.H6>
            </div>
          )}
        </CountUpPreset>
        <S.Margin $bottom="s" />

        <CountUpPreset value={stats.openDeviations.amount}>
          {({ countUpRef }) => (
            <div>
              <S.H6>
                Antal öppna avvikelser: <span ref={countUpRef} /> st
              </S.H6>
            </div>
          )}
        </CountUpPreset>
      </S.StatsInner>
    </>
  );
};

const CountUpPreset = ({
  children,
  value,
}: {
  children: (props: RenderCounterProps) => ReactNode;
  value: number;
}) => {
  return (
    <CountUp start={0} end={value} duration={3} delay={0}>
      {children}
    </CountUp>
  );
};
