import React, { useState } from 'react';
import { S } from './ResolvedAction.styles';
import { Checkbox } from '../../../../../../components/Checkbox';
import { TextArea } from '../../../../../../components/TextArea';
import { ResolvedDeviation } from '../../hooks/useDeviations';
import { DeviationCounterMeasureTypeEnum } from '@ansvar-sakerhet/api-client';
import { format, parseISO } from 'date-fns';

export const ResolvedAction = ({
  deviation,
}: {
  deviation: ResolvedDeviation;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <S.Container>
      <S.Trigger
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        $isExpanded={isExpanded}
      >
        <S.AnimatedIcon src="ChevronDown" />
        <S.RecommendationHeading>
          {getUserFriendlyDeviationType(deviation.type)}
        </S.RecommendationHeading>
      </S.Trigger>
      {isExpanded && (
        <S.Content>
          <S.ResultsAndActionsSection>
            <div>
              <S.ContentHeading>Resultat</S.ContentHeading>
              <S.ContentText>{deviation.result}</S.ContentText>
            </div>
            <div>
              <S.ContentHeading>Åtgärder</S.ContentHeading>
              <S.ContentText>{deviation.action}</S.ContentText>
            </div>
          </S.ResultsAndActionsSection>
          <S.CounterMeasuresSection>
            <Checkbox
              label="Rekommendation åtgärdad"
              checked={!!deviation.fixedAt}
              disabled
            />
            <TextArea
              label="Kommentera hur det har åtgärdats"
              value={deviation.fixDescription}
              disabled
            />

            <S.ContentInput
              type="date"
              label="Datum för åtgärd"
              value={format(parseISO(deviation.fixedAt), 'yyyy-MM-dd')}
              disabled
            ></S.ContentInput>
            <S.ContentInput
              label="Åtgärd genomförd av"
              value={deviation.fixedBy}
              disabled
            />
          </S.CounterMeasuresSection>
        </S.Content>
      )}
    </S.Container>
  );
};

function getUserFriendlyDeviationType(type: DeviationCounterMeasureTypeEnum) {
  switch (type) {
    case DeviationCounterMeasureTypeEnum.Id06Deviations:
      return 'Brott mot ID06 regelverk';
    case DeviationCounterMeasureTypeEnum.Identification:
      return 'Avsaknad av giltig legitimation';
    case DeviationCounterMeasureTypeEnum.Intoxication:
      return 'Tecken på onykterhet och drogpåverkan';
    case DeviationCounterMeasureTypeEnum.Other:
      return 'Övrig kontrollverksamhet';
    case DeviationCounterMeasureTypeEnum.PreApprovedSubcontractor:
      return 'Avstämning mot förgodkända UE';
    case DeviationCounterMeasureTypeEnum.SafetyEquipment:
      return 'Personlig säkerhetsutrustning';
    case DeviationCounterMeasureTypeEnum.Secondment:
      return 'Utstationeringsregelverket';
    case DeviationCounterMeasureTypeEnum.ShellProtection:
      return 'Skalskydd/inhägnad';
    case DeviationCounterMeasureTypeEnum.WorkEnvironment:
      return 'Arbetsmiljö';
    case DeviationCounterMeasureTypeEnum.WorkPermit:
      return 'Kontroll av arbetstillstånd och uppehållshandlingar';
    case DeviationCounterMeasureTypeEnum.WorkRegister:
      return 'Elektronisk personalliggare (EPL) – Skatteförfarandelagen';
  }
}
