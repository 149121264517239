import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';

const IconWithBg = styled(UIElements.Icon)`
  background-color: rgba(2, 62, 82, 0.2);
  padding: 0.2rem;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 4px;
`;

const DownloadButton = styled(UIElements.Button)<{ $noRisks: boolean }>`
  background-color: ${({ theme, $noRisks }) =>
    $noRisks ? theme.colors.lightRed : theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  font-weight: 300;
  padding: 0.6rem;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  max-width: 200px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray};
  }
`;

export const S = { ...UIElements, IconWithBg, DownloadButton };
