import React from 'react';
import { S } from './SideNavigationBar.styles';
import { useAuth } from '../../traits/hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';

export const SideNavigationBar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <S.Container>
      <S.NavigationItem
        onClick={() => navigate('/nyheter')}
        $chosen={location.pathname.includes('/nyheter')}
      >
        <S.Icon src="News" />
        <S.NavigationItemText>Startsida</S.NavigationItemText>
      </S.NavigationItem>

      <S.NavigationItem
        onClick={() => navigate('/jamforbar-statistik')}
        $chosen={location.pathname.includes('/jamforbar-statistik')}
      >
        <S.Icon src="Leaderboard" />
        <S.NavigationItemText>Statistikverktyg</S.NavigationItemText>
      </S.NavigationItem>

      <S.NavigationItem
        $chosen={location.pathname.includes('/mina-projekt')}
        onClick={() => navigate('/mina-projekt')}
      >
        <S.Icon src="Lists" />
        <S.NavigationItemText>Projekt</S.NavigationItemText>
      </S.NavigationItem>

      <S.NavigationItem
        $chosen={location.pathname.includes('/rodlistade-bolag')}
        onClick={() => navigate('/rodlistade-bolag')}
      >
        <S.Icon src="Warning" />
        <S.NavigationItemText>Rödlistade bolag</S.NavigationItemText>
      </S.NavigationItem>

      <S.NavigationItem
        onClick={() => navigate('/ramavtals-foretag')}
        $chosen={location.pathname.includes('/ramavtals-foretag')}
      >
        <S.Icon src="Contract" />
        <S.NavigationItemText>Ramavtalsföretag</S.NavigationItemText>
      </S.NavigationItem>

      <S.NavigationItem
        onClick={() => navigate('/faq')}
        $chosen={location.pathname.includes('/faq')}
      >
        <S.Icon src="FilledQuestion" />
        <S.NavigationItemText>FAQ</S.NavigationItemText>
      </S.NavigationItem>

      {/* <S.NavigationItem onClick={() => navigate('/hallbarhetsstatistik')}>
        <S.Icon src="PaperStatistics" />
        <S.NavigationItemText>Hållbarhetsstatistik</S.NavigationItemText>
      </S.NavigationItem> */}

      <S.NavigationBarFooter>
        <S.Icon src="Logout" />
        <S.NavigationItemText onClick={logout}>Logga ut</S.NavigationItemText>
      </S.NavigationBarFooter>
    </S.Container>
  );
};
