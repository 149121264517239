import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { keys } from './frameCompany.keys';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../../features/redListedCompanies/helpers/debounceSearch';

export function useGetAllFrameCompanies(
  take?: number,
  skip?: number,
  search?: string
) {
  const getApi = useApi();
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const debounce = useDebounce();

  const getFrameCompanies = async () => {
    const api = await getApi();

    const res = await api.frameCompanyFindAll(take, skip, debouncedSearch);

    return res;
  };

  const query = useQuery({
    queryKey: keys.findAll(take, skip, debouncedSearch),
    queryFn: getFrameCompanies,
  });

  useEffect(() => {
    if (typeof search === 'string') {
      debounce(() => setDebouncedSearch(search), 300);
    }
  }, [search]);

  return {
    ...query,
    data: query.data?.data,
    count: query.data?.headers['x-total-count'],
  };
}
