import { H3 } from '@ansvar-sakerhet/ui/src/typography/H3';
import React, { useState } from 'react';
import { S } from './FrameCompanies.styles';
import { useGetAllFrameCompanies } from '../../../../traits/api/frameCompany/useGetAllFrameCompanies';
import { Pagination } from '../../../../components/Pagination';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../../../components/Loading';
import { FrameCompaniesTable } from './components/FrameCompaniesTable';
import { SearchInput } from '../../../../components/SearchInput';

export const FrameCompanies: React.FC = () => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const PAGE_SIZE = 10;
  const { data, isLoading, count } = useGetAllFrameCompanies(
    PAGE_SIZE,
    page * PAGE_SIZE,
    search
  );

  if (isLoading || !data) {
    return (
      <div>
        <S.Flex $flexDir="row" $justify="between">
          <H3>Ramavtalsföretag</H3>
          <S.Button onClick={() => navigate('/ramavtals-foretag/skapa')}>
            <S.Icon src="Plus" /> Nytt Företag
          </S.Button>
        </S.Flex>
        <S.Margin $top="m" />
        <SearchInput
          placeholder="Sök efter organisationsnummer eller namn"
          input={search}
          setInput={setSearch}
        />
        <S.Margin $top="m" />
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <S.Flex $flexDir="row" $justify="between">
        <H3>Ramavtalsföretag</H3>
        <S.Button onClick={() => navigate('/ramavtals-foretag/skapa')}>
          <S.Icon src="Plus" /> Nytt Företag
        </S.Button>
      </S.Flex>
      <S.Margin $top="m" />
      <SearchInput
        placeholder="Sök efter organisationsnummer eller namn"
        input={search}
        setInput={setSearch}
      />
      <S.Margin $top="m" />
      <S.FrameCompanies>
        <FrameCompaniesTable frameCompanies={data} />
      </S.FrameCompanies>
      <S.Margin $top="m" />
      <Pagination
        numberOfPages={Math.ceil(count / PAGE_SIZE)}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};
