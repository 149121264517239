import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import Organisationsnummer from 'organisationsnummer';

export const addCustomerUserForm = z.object({
  companyName: z.string().min(1, 'Företagsnamn måste anges'),
  registrationNumber: z
    .string()
    .min(1, 'Org.nr måste anges')
    .refine((val) => Organisationsnummer.valid(val), {
      message: 'Org.nr är inte giltigt',
    }),
  code: z.string().min(1, 'Kod måste anges'),
});

export type addFrameCompanyFormValues = z.infer<typeof addCustomerUserForm>;

export const useAddFrameCompanyForm = () => {
  const methods = useForm<addFrameCompanyFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(addCustomerUserForm),
    defaultValues: {
      companyName: '',
      registrationNumber: '',
      code: '',
    },
  });

  return methods;
};
