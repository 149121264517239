const all = ['frame-company'];

const findAll = (take?: number, skip?: number, search?: string) => [
  ...all,
  take,
  skip,
  search,
];

const findOne = (newsId: string | undefined) => [...all, newsId];

export const keys = { all, findAll, findOne };
