import { useMemo, useState } from 'react';
import {
  BackgroundControlWithFiles,
  BackgroundControlWithFilesRiskEnum,
  FindAllFrameCompaniesResponse,
} from '@ansvar-sakerhet/api-client';
import { format } from 'date-fns';
import React from 'react';
import { findLatestReport } from '../utils/findLatestReport';
import { OrderBackgroundControlButton } from '../../OrderBackgroundControlButton';
import { DownloadReportButton } from '../../DownloadReportButton';
import { sortFrameCompanies } from '../utils/sortFrameCompanies';

export enum SortControls {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RegistrationNumberAsc = 'registrationNumber_ASC',
  RegistrationNumberDesc = 'registrationNumber_DESC',
  LatestBackgroundControlAsc = 'latestBackgroundControl_ASC',
  LatestBackgroundControlDesc = 'latestBackgroundControl_DESC',
  BackgroundControlResultAsc = 'backgroundControlStatus_ASC',
  BackgroundControlResultDesc = 'backgroundControlStatus_DESC',
}

export const useFrameCompaniesTableData = (
  frameCompanies: FindAllFrameCompaniesResponse[]
) => {
  const [sort, setSort] = useState(SortControls.NameAsc);
  const sortedData = useMemo(() => {
    return [...sortFrameCompanies(frameCompanies, sort)];
  }, [frameCompanies, sort]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Namn',
        accessor: 'name',
        onClick: () => {
          if (sort === SortControls.NameAsc) {
            return setSort(SortControls.NameDesc);
          }

          return setSort(SortControls.NameAsc);
        },
        iconSrc: () =>
          sort === SortControls.NameAsc ? 'ArrowDropUp' : 'ArrowDropDown',
      },
      {
        Header: 'Org.nr',
        accessor: 'registrationNumber',
        onClick: () => {
          if (sort === SortControls.RegistrationNumberAsc) {
            return setSort(SortControls.RegistrationNumberDesc);
          }

          return setSort(SortControls.RegistrationNumberAsc);
        },
        iconSrc: () =>
          sort === SortControls.RegistrationNumberAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Senaste granskning',
        accessor: 'latestBackgroundControl',
        onClick: () => {
          if (sort === SortControls.LatestBackgroundControlAsc) {
            return setSort(SortControls.LatestBackgroundControlDesc);
          }

          return setSort(SortControls.LatestBackgroundControlAsc);
        },
        iconSrc: () =>
          sort === SortControls.LatestBackgroundControlAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Bortvalt',
        accessor: 'optedOut',
        onClick: null,
        iconSrc: null,
      },
      {
        Header: 'Riskbedömning',
        accessor: 'risks',
        onClick: () => {
          if (sort === SortControls.BackgroundControlResultAsc) {
            return setSort(SortControls.BackgroundControlResultDesc);
          }

          return setSort(SortControls.BackgroundControlResultAsc);
        },
        iconSrc: () =>
          sort === SortControls.BackgroundControlResultAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Rapport',
        accessor: 'report',
        onClick: null,
        iconSrc: null,
      },
    ] as const;
  }, [sort]);

  const data = useMemo(() => {
    return sortedData.map((contractor) => {
      const latestBackgroundControl = getLatestBackgroundControl(contractor);
      const latestBackgroundControlDate = latestBackgroundControl
        ? format(new Date(latestBackgroundControl.createdAt), 'yyyy/MM/dd')
        : '-';

      return {
        name: contractor.name,
        registrationNumber: contractor.registrationNumber,
        latestBackgroundControl: latestBackgroundControlDate,
        optedOut: optedOutTranslation(latestBackgroundControl?.optedOut) ?? '-',
        risks: AcceptanceTranslation[latestBackgroundControl?.risk] ?? '-',
        report: getDynamicReportButton(latestBackgroundControl, contractor),
      };
    });
  }, [sortedData]);

  return { columns, data };
};

const optedOutTranslation = (optedOut: boolean) => (optedOut ? 'Ja' : 'Nej');

const AcceptanceTranslation: Record<
  BackgroundControlWithFilesRiskEnum,
  string
> = {
  NoRisk: 'Inga risker identifierade.',
  LesserRisk: 'Avvikelser identifierade, OK att samarbeta.',
  MediumHighRisk: 'Avvikelser identifierade, åtgärder föreslås.',
  HighRisk:
    'Väsentliga risker. Avrådan från att anlita företaget eller åtgärda avvikelser.',
};

function getLatestBackgroundControl(company: FindAllFrameCompaniesResponse) {
  return company.BackgroundControl.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0];
}

function getDynamicReportButton(
  backgroundControl: BackgroundControlWithFiles | undefined,
  contractor: FindAllFrameCompaniesResponse
) {
  switch (backgroundControl?.risk) {
    case BackgroundControlWithFilesRiskEnum.NoRisk:
    case BackgroundControlWithFilesRiskEnum.LesserRisk:
    case BackgroundControlWithFilesRiskEnum.MediumHighRisk:
    case BackgroundControlWithFilesRiskEnum.HighRisk:
      const downloadUrl = findLatestReport(backgroundControl)?.file.url;

      return (
        <DownloadReportButton
          risk={backgroundControl.risk}
          downloadUrl={downloadUrl}
        />
      );
    default:
      return <OrderBackgroundControlButton company={contractor} />;
  }
}
