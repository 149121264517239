import React, { useEffect, useState } from 'react';
import { MultiSelect, Option } from '../../../../../components/MultiSelect';
import { S } from './SubcontractorsFlow.styles';
import { useGetAllCompaniesWithStatistics } from '../../../../../traits/api/companies/useGetAllCompanies';
import { Loading } from '../../../../../components/Loading';
import { useContractors } from './hooks/useContractors';
import { useGetCompanyStats } from '../../../../../traits/api/stats/useGetCompanyStats';
import { FindAllCompanyStatisticsResponse } from '@ansvar-sakerhet/api-client';
import { BarChart, ChartsReferenceLine } from '@mui/x-charts';
import { useGetCompanyCountryStats } from '../../../../../traits/api/stats/useGetCompanyCountryStats';
import { useContractorsStatistics } from './hooks/useContractorsStatistics';

type CountryStats = {
  controlledAndSignedIn: number;
  controlledNotSignedIn: number;
  notFound: number;
  id06CardDeviating: number;
  safeConstructionTraining: number;
  invalidIdentification: number;
  safetyEquipment: number;
  workPermit: number;
};

export const SubcontractorsFlow = () => {
  const { data, isLoading } = useGetAllCompaniesWithStatistics({ take: 1_000 });
  const { subcontractors } = useContractors(data ?? []);
  const [chosenCompanies, setChosenCompaniesOptions] = useState<Option[]>([]);
  const companyStats = useGetCompanyStats();
  const companyCountry = useGetCompanyCountryStats();

  const [stats, setStats] = useState<FindAllCompanyStatisticsResponse[]>([]);
  const [statsCountry, setStatsCountry] = useState<CountryStats | null>(null);

  const {
    labelX,
    dataX1,
    dataX2,
    dataX3,
    dataX4,
    dataX5,
    dataX6,
    dataX7,
    dataX8,
  } = useContractorsStatistics({ statsCountry, stats });

  useEffect(() => {
    const getStats = async (companies: string[]) => {
      const a = await companyStats.mutateAsync(companies);
      const data = a.data as unknown;
      return data as FindAllCompanyStatisticsResponse[];
    };

    const getCountry = async () => {
      const a = await companyCountry.mutateAsync();
      return a.data;
    };

    const companies = chosenCompanies.map(({ key }) => key);

    getStats(companies).then((values) => {
      setStats(values);
    });

    getCountry().then((values) => {
      const b = values as unknown;
      setStatsCountry(b as CountryStats);
    });
  }, [chosenCompanies]);

  if (isLoading || !data) {
    return <Loading />;
  }

  const options: Option[] = subcontractors.map(({ companyId, name }) => ({
    key: companyId,
    label: name,
  }));

  return (
    <>
      <MultiSelect
        searchPlaceholder="Sök underentreprenörer"
        chosenOptions={chosenCompanies}
        setChosenOptions={setChosenCompaniesOptions}
        options={options}
        label="Välj vilka underentreprenörer du vill jämföra"
      />
      <S.Margin $top="m" />

      <S.Flex $flexWrap="wrap" $justify={'between'} $gap="10px">
        {showBarChart(
          dataX1,
          labelX,
          'Kontrollerat och inloggade i elektronisk personalliggare (EPL)'
        )}
        {showBarChart(dataX2, labelX, 'Kontrollerat, ej inloggade')}
        {showBarChart(
          dataX3,
          labelX,
          'Inloggade i elektronisk personalliggare (EPL), ej funna'
        )}
        {showBarChart(dataX4, labelX, 'Avvikelser mot ID06 regelverk')}
        {showBarChart(dataX5, labelX, 'Safe Construction Training saknas')}
        {showBarChart(dataX6, labelX, 'Avsaknad av giltig legitimation')}
        {showBarChart(
          dataX7,
          labelX,
          'Personlig skyddsutrustning, avvikelse mot arbetsmiljöverkets säkerhetsföreskrifter	'
        )}
        {showBarChart(
          dataX8,
          labelX,
          'Upphålls- och arbetstillstånd saknas (AT/UT/PUT/AT-UND)'
        )}
      </S.Flex>
    </>
  );
};

const showBarChart = (data: number[], labelX: string[], title: string) => {
  const countryVal = data[data.length - 1];

  return (
    <S.ShowBarChartContainer>
      <S.H6>{title}</S.H6>
      <BarChart
        layout="horizontal"
        series={[{ data, valueFormatter: (v) => `${v}%` }]}
        yAxis={[
          {
            tickLabelStyle: {
              angle: -45,
              textAnchor: 'end',
            },
            scaleType: 'band',
            data: labelX,
            colorMap: {
              type: 'ordinal',
              colors: data.map((d, i) =>
                i === data.length - 1 ? '#C8AB5D' : '#EECC71'
              ),
            },
          },
        ]}
        margin={{ left: 160 }}
        width={500}
        height={250}
        xAxis={[{ label: 'Andel (%)', min: 0, max: 100 }]}
      >
        <ChartsReferenceLine
          x={countryVal}
          labelAlign="end"
          lineStyle={{
            strokeDasharray: '10 5',
          }}
        />
      </BarChart>
    </S.ShowBarChartContainer>
  );
};
