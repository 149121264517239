import { UIElements } from '@ansvar-sakerhet/ui';
import { styled } from 'styled-components';

const StatsInner = styled(UIElements.Flex)`
  width: 100%;
  height: 100%;
  min-height: 200px;
  background-color: white;
  padding: 20px;
  flex-direction: column;

  @media (min-width: 1350px) {
    width: calc(50% - 5px);
  }
`;

const RiskAssessmentContainer = styled(UIElements.Flex)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: white;
  gap: 20px;
`;

const RiskAssesmentInner = styled(UIElements.Flex)`
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const RiskSquare = styled(UIElements.Flex)<{ color: string }>`
  height: 100%;
  width: 100%;
  background-color: ${({ color }) => color};
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing.s};
`;

export const S = {
  ...UIElements,
  StatsInner,
  RiskSquare,
  RiskAssessmentContainer,
  RiskAssesmentInner,
};
