import { H3 } from '@ansvar-sakerhet/ui/src/typography/H3';
import React from 'react';
import { S } from './CreateCompany.styles';
import { useCreateFrameCompany } from '../../../../traits/api/frameCompany/useCreateFrameCompany';
import { toast } from '../../../../components/Toast';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../../../components/Input';
import {
  addFrameCompanyFormValues,
  useAddFrameCompanyForm,
} from './hooks/useAddFrameCompanyForm';

export const CreateFrameCompany: React.FC = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useAddFrameCompanyForm();
  const createFrameCompanyMutation = useCreateFrameCompany();

  const handleSave = async (data: addFrameCompanyFormValues) => {
    try {
      await createFrameCompanyMutation.mutateAsync({
        name: data.companyName,
        registrationNumber: data.registrationNumber,
        code: data.code,
      });
      navigate('/ramavtals-foretag');
    } catch (err) {
      toast.error('Något gick fel när frågan skulle uppdateras');
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <S.BackButton onClick={() => navigate(-1)}>
        <S.Icon src="ChevronLeft" />
        <p>Tillbaka</p>
      </S.BackButton>
      <S.Container>
        <H3>Skapa nytt ramavtalsföretag</H3>
        <S.Margin $top="m" />
        <div>
          <S.CreateFrameCompany>
            <Input
              label="Företagsnamn"
              {...register('companyName')}
              error={errors.companyName}
            />
            <Input
              label="Organisationsnummer"
              {...register('registrationNumber')}
              error={errors.registrationNumber}
            />
            <Input label="SNI-Kod" {...register('code')} error={errors.code} />
            <S.Button type="submit">Skapa</S.Button>
          </S.CreateFrameCompany>
        </div>
      </S.Container>
    </form>
  );
};
