import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';

const BackButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  & > div {
    padding-bottom: 2rem;
  }
`;

const InteractiveText = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.info};
`;

const Section = styled.div<{ $maxWidth?: string }>`
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth};` : '')}
`;

export const S = {
  ...UIElements,
  Container,
  BackButton,
  InteractiveText,
  Section,
};
