import React, { useState } from 'react';
import { useMemo } from 'react';
import {
  ControlWithCounterMeasures,
  FindMyProjectWithControls,
} from '@ansvar-sakerhet/api-client';
import { format } from 'date-fns';
import { getAmountOfDeviations } from '../utils/getAmountOfDeviations';
import { DownloadReportButton } from '../components';
import { sortControls } from '../utils/sortControls';
import { findLatestReport } from '../utils/findLatestReport';

export enum SortControls {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SiteManagerAsc = 'siteManager_ASC',
  SiteManagerDesc = 'siteManager_DESC',
  AmountOfDeviationsAsc = 'amountOfDeviations_ASC',
  AmountOfDeviationsDesc = 'amountOfDeviations_DESC',
  ReportAsc = 'report_ASC',
  ReportDesc = 'report_DESC',
}

export const useControlsTableData = (
  controls: FindMyProjectWithControls['controls']
) => {
  const [sort, setSort] = useState(SortControls.DateDesc);
  const sortedControls = useMemo(() => {
    return [...sortControls(controls, sort)];
  }, [controls, sort]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Datum',
        accessor: 'date',
        onClick: () => {
          if (sort === SortControls.DateAsc) {
            return setSort(SortControls.DateDesc);
          }

          return setSort(SortControls.DateAsc);
        },
        iconSrc: () =>
          sort === SortControls.DateAsc ? 'ArrowDropUp' : 'ArrowDropDown',
      },
      {
        Header: 'Platschef',
        accessor: 'siteManager',
        onClick: () => {
          if (sort === SortControls.SiteManagerAsc) {
            return setSort(SortControls.SiteManagerDesc);
          }

          return setSort(SortControls.SiteManagerAsc);
        },
        iconSrc: () =>
          sort === SortControls.SiteManagerAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Antal avvikelser',
        accessor: 'amountOfDeviations',
        onClick: () => {
          if (sort === SortControls.AmountOfDeviationsAsc) {
            return setSort(SortControls.AmountOfDeviationsDesc);
          }

          return setSort(SortControls.AmountOfDeviationsAsc);
        },
        iconSrc: () =>
          sort === SortControls.AmountOfDeviationsAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Rapport',
        accessor: 'report',
        onClick: null,
        iconSrc: null,
      },
    ] as const;
  }, [sort]);

  const data = useMemo(() => {
    return sortedControls.map((control) => {
      return {
        date: format(new Date(control.date), 'dd/MM/yyyy HH:mm'),
        siteManager: control.siteManager,
        amountOfDeviations: `${getAmountOfDeviations(control)} st`,
        report: report(control.controlId),
      };
    });
  }, [sortedControls]);

  return { columns, data };
};

function report(control?: string) {
  if (!control) {
    return null;
  }
  return <DownloadReportButton downloadUrl={control} />;
}
