import { H3 } from '@ansvar-sakerhet/ui/src/typography/H3';
import React from 'react';
import { S } from './ImportCompanies.styles';
import { Button } from '@ansvar-sakerhet/ui/src/buttons/Button';
import { useNavigate } from 'react-router-dom';

export const ImportFrameCompany: React.FC = () => {
  const navigate = useNavigate();
  const handleSave = async () => {
    console.log('TODO: upload');
  };

  return (
    <>
      <H3>Skapa nytt ramavtalsföretag</H3>
      <Button onClick={() => navigate('/ramavtals-foretag')}>Tillbaka</Button>
      <S.Margin $top="m" />
      <S.Flex>
        <S.CreateFrameCompany>
          <S.Button type="button">Ladda upp fil</S.Button>

          <S.HiddenInput type="file" accept=".pdf" />
          <Button
            type="submit"
            onClick={() => {
              handleSave();
            }}
          >
            Skapa
          </Button>
        </S.CreateFrameCompany>
      </S.Flex>
    </>
  );
};
